import React from "react";
import DashboardLayout from "../../components/layout/dashboardLayout";

const DashboardPage = () => {
  return (
    <div>
      <DashboardLayout />
    </div>
  );
};

export default DashboardPage;
