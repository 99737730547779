import React from "react";
import "../styles/styles-components/logincard_info.css";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

function LoginCardInfo() {
  const navigate = useNavigate();

  const loginButton = () => {
    navigate("/auth/login");
  };

  return (
    <Row justify="center">
      <Col xs={24} sm={20} md={16} lg={12} xl={8}>
        <div className="card-container">
          <h1 className="card-title">
            Faça seu login e administre melhor seu condomínio
          </h1>
          <Button
            type="primary"
            size="large"
            className="card-button"
            onClick={loginButton}
          >
            <strong>Entrar</strong>
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default LoginCardInfo;
