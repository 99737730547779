import { Carousel, Col, Divider, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./testimonials-carousel.css";
import testimonials1 from "../assets/images/testimonials.svg";
import testimonials2 from "../assets/images/testimonials2.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { baseurl } from "../lib/settings";
const { Title } = Typography;
function TestimonialsCarousel() {
  const [testim, setTestimon] = useState([]);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   baseurl.get('api/v2/resident/comments/').then((resp) => {
  //     setTestimon(resp.data);
  //   })
  // }, [])

  const testimonials = [
    {
      id: 1,
      image: testimonials1,
      comment: "Muito boa a plataforma, congratulations",
    },
    {
      id: 2,
      image: testimonials2,
      comment: "Muito boa a plataforma, congratulations.",
    },
    {
      id: 3,
      image: testimonials1,
      comment: "Muito boa a plataforma, congratulations",
    },
  ];

  const prevArrow = <LeftOutlined className="carousel-arrow" />;
  const nextArrow = <RightOutlined className="carousel-arrow" />;

  return (
    <div className="testimonial-carousel-container">
      {/* <h2 className="carousel-title">O que dizem sobre nós?</h2> */}
      <Divider plain style={{}} orientation="right">
        <Title>O que dizem sobre nós</Title>
      </Divider>
      <br />
      <br />
      <Carousel
        className="testimonial-carousel"
        autoplay
        prevArrow={prevArrow}
        nextArrow={nextArrow}
      >
        {testimonials.map((testimonial) => (
          <div key={testimonial.id}>
            <Row justify="center" align="middle">
              {!isMobile && (
                <>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="carousel-image-container">
                      <img
                        src={testimonial.image}
                        alt="Imagem bonita"
                        className="carousel-image"
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className="testimonial-comment">
                      <span className="quote-mark">"</span>
                      {testimonial.comment}
                      <span className="quote-mark">"</span>
                    </div>
                  </Col>
                </>
              )}
              <br />
              {isMobile && (
                <Col span={24}>
                  <br />

                  <div className="testimonial-comment-mobile">
                    <span className="quote-mark">"</span>
                    {testimonial.comment}
                    <br />
                    <span className="quote-mark">"</span>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default TestimonialsCarousel;
