import React from "react";
import { Card, Col, Image, Row } from "antd";
import "./dashboardilustrator.css";
import dashIlustrator from "../assets/images/croped.png";
import iconProf from "../assets/icons/iconProf.svg";
import iconProfs from "../assets/icons/iconProfs.svg";
import iconMon from "../assets/icons/iconMon.svg";
import dasboardImg from "../assets/icons/dasboardImg.svg";

function IlustratorDashboard() {
  return (
    <div style={{ backgroundColor: "#F7F9FF", overflow: "hidden" }}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100%",
          marginLeft: "20%",
          marginRight: "20%",
        }}
      >
        <Col xs={24} sm={12} md={12}>
          {/* <Card
            style={{
              margin: "30px",
              border: "none",
            }}
          > */}
          <div
            style={{
              height: "260px",
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={dasboardImg}
              style={{
                maxWidth: "100%",
                width: "500px",
                height: "300px",
                maxHeight: "100%",
              }}
              alt="Dashboard Illustration"
            />
          </div>
          {/* </Card> */}
        </Col>
        <Col xs={24} sm={12} md={12}>
          <Card style={{ margin: "30px", border: "none", height: "320px" }}>
            <div style={{}}>
              <img src={iconProf} alt="Logo" style={{ marginLeft: "20px" }} />
              <h3
                className="small-card-title"
                style={{ marginLeft: "20px", paddingBottom: "10px" }}
              >
                Administrativo
              </h3>
              <ul style={{ marginLeft: "45px" }}>
                <li>Assembleia online</li>
                <li>Cadastro de moradores</li>
                <li>Reservas</li>
                <li>Eventos Periódicos</li>
                <li>Multas / Advertências</li>
              </ul>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={12}>
          <Card style={{ margin: "30px", border: "none", height: "320px" }}>
            <img
              src={iconProfs}
              alt="Logo"
              className="small-card-logo"
              style={{ marginLeft: "20px" }}
            />
            <h3
              className="small-card-title"
              style={{ marginLeft: "20px", paddingBottom: "10px" }}
            >
              Moradores
            </h3>
            <ul style={{ marginLeft: "45px" }}>
              <li>Assembleia online</li>
              <li>Actualizacao de Cadastro</li>
              <li>Documentos</li>
              <li>Visitantes Autorizados</li>
              <li>Encomendas</li>
              <li>Classificados</li>
            </ul>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={12}>
          <Card style={{ margin: "30px", border: "none", height: "320px" }}>
            <img
              src={iconMon}
              alt="Logo"
              className="small-card-logo"
              style={{ marginLeft: "20px" }}
            />
            <h3
              className="small-card-title"
              style={{ marginLeft: "20px", paddingBottom: "10px" }}
            >
              Financeiro
            </h3>
            <ul style={{ marginLeft: "45px" }}>
              <li>Taxa de Condomínio</li>
              <li>Cobranças</li>
              <li>Contrato</li>
              <li>Pagamentos</li>
              <li>Carta de Cobranç</li>
              <li>Correção Monetária, Multa</li>
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default IlustratorDashboard;
