import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landingPage/landingPage";
import PrivateRoutes from "./PrivateRoutes";
import Resident from "./pages/resident/resident";
import DepositSimulator from "./pages/resident/depositSimulator";
import LoginPage from "./pages/login/login";
import DashboardPage from "./pages/dashboard/dashboardPage";
import NotFoundPage from "./pages/404/notfoundPage";

export default function Rotas() {
  return (
    <div>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route
              element={<DashboardPage />}
              path="/dashboard/resident/"
              exact
            />
            <Route
              element={<Resident />}
              path="/dashboard/resident/residents/"
              exact
            />
            <Route
              element={<DepositSimulator />}
              path="/dashboard/resident/deposit-simulator/"
              exact
            />
          </Route>

          <Route element={<LandingPage />} path="/" />
          <Route element={<LoginPage />} path="/auth/login" />
          <Route element={<NotFoundPage />} path="*" />
        </Routes>
      </Router>
    </div>
  );
}
