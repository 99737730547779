import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { AiOutlineBars } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo/logo-condominio.png";
import "./navbar.css";
import { Button } from "antd";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const callLoginPage = () => {
    navigate("/auth/login");
  };

  return (
    <div className={navbar ? "fixar active" : "fixar"}>
      <nav className="navbar container">
        <div className="logo">
          <p className="logo-text">
            <Link to="/">
              {" "}
              <img src={logo} alt="logo" />
            </Link>
          </p>
        </div>
        <menu>
          <ul
            className="nav-links active"
            id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}
          >
            <li>
              <Link to="/">Contacto</Link>
            </li>
            <li>
              <Link to="/">Sobre Nós</Link>
            </li>
            {/* <li className='entrar-button'>
              <Link to="/auth/login">Entrar</Link>
          </li> */}
            <Button
              type="primary"
              onClick={callLoginPage}
              style={{
                width: "100px",
                color: "#0252C5",
                borderColor: "#7594FB",
              }}
              ghost
            >
              {" "}
              Entrar{" "}
            </Button>
          </ul>
        </menu>
        <div className="menu-icons" onClick={toggleMenu}>
          {showMenu ? (
            <RiCloseLine color="#fff" size={30} />
          ) : (
            <AiOutlineBars color="#fff" size={27} />
          )}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
