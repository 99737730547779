import React from "react";
import "../../components/styles/styles-pages/landingpage.css";
import HeroSection from "../../components/herosection/herosection";
import Navbar from "../../components/navbar/navbar";
import ManagementCards from "../../components/info-cards/managementcards";
import AndroidPhone744 from "../../components/Android744phone/android-phone";
import TestimonialsCarousel from "../../components/testimonials/testimonials";
import LoginCardInfo from "../../components/info-cards/logincard";
import Footer from "../../components/footer/footer";
import IlustratorDashboard from "../../components/dashboard/lp-ilustrator";

const LandingPage = () => {
  return (
    <>
      {/* <header className="header-bg1"> */}
      <Navbar />
      {/* </header> */}
      <HeroSection />
      {/* <br /> */}
      {/* <br /> */}
      {/* <br />
      <br />
      <br /> */}
      <ManagementCards />
      {/* <ManagementCards style={{ marginTop: '1px'}} /> */}
      <IlustratorDashboard />
      <AndroidPhone744 />
      <br />
      <TestimonialsCarousel />
      <br />
      <br />
      <LoginCardInfo />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default LandingPage;
