import React from "react";
import { Card, Typography } from "antd";
import iconArrow from "../assets/icons/iconArrow.svg";
import iconMoney from "../assets/icons/iconMoney.svg";
import iconPhone from "../assets/icons/iconPhone.svg";
import iconProfile from "../assets/icons/iconProfile.svg";
import "./managementcards.css";

const { Meta } = Card;
const cardsData = [
  {
    logo: <img src={iconProfile} size={64} />,
    title: "Administrativo",
  },
  {
    logo: <img src={iconMoney} size={64} />,
    title: "Financeiro Automatizado",
  },
  {
    logo: <img src={iconArrow} size={64} />,
    title: `Aplicativo para moradores`,
  },
  {
    logo: <img src={iconPhone} size={64} />,
    title: "Gerenciamento pelo USSD",
  },
];

const { Title, Text } = Typography;

function ManagementCards() {
  return (
    <div className="container-management-cards">
      <div className="cards">
        {cardsData.map((card, index) => (
          <Card
            key={index}
            className={`card ${
              card.title.split(" ").length > 2 ? "wrap-text" : ""
            }`}
            hoverable
            cover={<div className="card-logo">{card.logo}</div>}
          >
            <p className="card-title-title">
              <Meta title={card.title} />
            </p>
          </Card>
        ))}
      </div>
      <div className="footer-cards">
        <h1 className="message-business-cards">
          O que mais podes fazer com o nosso<span className="p-sucess-color">software</span>
        </h1>
        {/* <Title
          style={{ color: "rgb(102, 102, 102)" }}
          xs={{ fontSize: "16px" }}
          sm={{ fontSize: "16px" }}
          md={{ fontSize: "18px" }}
          lg={{ fontSize: "40px" }}
          xl={{ fontSize: "40px" }}
        >
          O que mais podes fazer com o nosso {""}
          <span className="p-sucess-color">software</span>
        </Title> */}
      </div>
    </div>
  );
}

export default ManagementCards;
