import Rotas from "./router";
import "./app.css";

function App() {
  return (
    <div>
      <Rotas />
    </div>
  );
}

export default App;
