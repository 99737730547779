import React from "react";
import hello from "../../assets/images/cell-phone.svg";

function DashboardMainContent() {
  return (
    <main>
      <div className="main__container">
        <div className="main__title">
          <img src={hello} alt="hello" />
          <div className="main__greeting">
            <h1>Hello! MAC</h1>
            <p>Bem vindo ao seu menu principal</p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DashboardMainContent;
