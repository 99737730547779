import React from "react";
import { Layout } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./footer.css";
import spz_logo from "../assets/logo/speranza-logo.png";
import cond_logo from "../assets/logo/logo-branco.png";

const { Footer } = Layout;

const CustomFooter = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <footer>
        <div className="footer-bg">
          <div className="footer-content">
            <div className="logo-footer">
              <img src={cond_logo} alt="Logotipo - Condominio" />
            </div>
            <div className="footer-column">
              <h2></h2>
              <ul>
                <li>264 Manon road F1 456 caron</li>
                <li>Town</li>
                <li>Office No 3457</li>
                <li>suporte@condominio.co.mz</li>
              </ul>
            </div>
            <div className="footer-column">
              <h2>Sobre nós</h2>
              <ul>
                <li>Blog</li>
                <li>Team</li>
                <li>Contactos</li>
              </ul>
            </div>
            <div className="footer-column"></div>
            <div className="footer-column"></div>
          </div>
        </div>
      </footer>
      <div className="footer-black">
        <span>
          Criado com ❤️ pela <img src={spz_logo} alt="logotipo_spz" />
        </span>
      </div>
    </>
  );
};

export default CustomFooter;
