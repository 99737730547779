import React from "react";
import cellphone from "../assets/images/cell-phone.svg";
import "./androidphone.css";
import { Card, Col, Image, Row } from "antd";

function AndroidPhone744() {
  return (
    <div style={{ overflow: "hidden" }}>
      <Row justify="center" align="middle">
        <Col>
          <Card xs={24} sm={12} md={12} style={{ border: "none" }}>
            <Image
              preview={false}
              src={cellphone}
              alt="Phone"
              className="phone-image"
              height={420}
            />
          </Card>
        </Col>
        <Col style={{ height: "390px" }}>
          <div style={{ paddingBottom: "25px" }}>
            <h1 className="title-cond">
              Como cadastrar <br /> Meu{" "}
              <span className="p-sucess-color">
                <strong>Condomínio</strong>
              </span>
            </h1>
          </div>
          <Card
            className="card-blur"
            xs={1}
            sm={1}
            md={1}
            style={{
              backgroundColor: "#f2f2f2",
              width: "430px",
            }}
          >
            <ul className="digite">
              <li>
                Digite{" "}
                <span className="p-sucess-color">
                  <strong>*744#</strong>
                </span>{" "}
                no teu Smartphone
              </li>
              <li>
                Opção{" "}
                <span className="p-sucess-color">
                  <strong>1. Condomínio</strong>
                </span>
              </li>
              <li>Selecione uma das Opções</li>
              <ul className="cond-list">
                <li>&nbsp;&nbsp;&nbsp;# Criar Condomínio</li>
                <li>&nbsp;&nbsp;&nbsp;## Solicitar Adesão</li>
                <li>&nbsp;&nbsp;&nbsp;###Convites Recebidos</li>
              </ul>
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AndroidPhone744;
