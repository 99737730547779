import React, { useState } from "react";
import { Button, Input, Form, Row, Col } from "antd";
import condLogo from "../../components/assets/logo/logo-condominio.svg";
import loginSVG from "../../components/assets/images/login.svg";
import LoginHeader from "../../components/navbar/login-navbar";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [condoCode, setCondoCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (showOtpInput) {
      const response = await verifyOtpCode(otpCode);
      if (response.status === 200) {
      } else {
        alert("Código OTP inválido. Por favor, tente novamente.");
        setOtpCode("");
      }
    } else {
      const response = await checkPhoneNumber(phoneNumber, condoCode);
      if (response.status === 200) {
        setShowOtpInput(true);
      } else {
        alert(
          "Número de telefone não encontrado no código do condomínio. Por favor, tente novamente."
        );
        setPhoneNumber("");
      }
    }
  };

  const checkPhoneNumber = async (phoneNumber, condoCode) => {
    return { status: 200 };
  };

  const verifyOtpCode = async (otpCode) => {
    return { status: 200 };
  };

  return (
    <>
      {/* <LoginHeader /> */}
      <Row style={{ height: "100vh" }}>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              // padding: '20px',
            }}
          >
            {showOtpInput ? (
              <Form style={{ width: "100%", maxWidth: "300px" }}>
                <img src={condLogo} alt="condLogo" style={{}} />

                <Form.Item>
                  <h1 style={{}}>Faça seu login</h1>
                  <p style={{}}>Insira o código OTP que recebeu por SMS</p>
                  <Input
                    placeholder="Código OTP"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={handleLogin} block>
                    Entrar
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form style={{ width: "100%", maxWidth: "300px" }}>
                <img src={condLogo} alt="condLogo" style={{}} />

                <h1 style={{}}>Faça seu login</h1>
                <p style={{}}>Insira o código do seu Condomínio</p>
                <Form.Item>
                  <Input
                    placeholder="Código do Condomínio"
                    value={condoCode}
                    onChange={(e) => setCondoCode(e.target.value)}
                  />
                </Form.Item>
                <p style={{}}>Insira o seu número de celular</p>
                <Form.Item>
                  <Input
                    placeholder="Número de Telefone"
                    value={phoneNumber}
                    prefix="+258"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={handleLogin} block>
                    Entrar
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </Col>
        <Col span={12}>
          <div style={{ width: "100%", height: "100%" }}>
            <img
              src={loginSVG}
              alt="loginSVG"
              style={{ maxWidth: "100%", height: "100%" }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LoginPage;
