import React from "react";
import dashLogo from "../../assets/logo/speranza-logo.png"
import condLogo from "../../assets/logo/logo-condominio.png"
import "../../styles/styles-components/dashboard-sidebar.css"
import {
    ArrowLeftOutlined,
    DashboardOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    TeamOutlined,
    WalletOutlined
} from "@ant-design/icons";


const Sidebar = ({ sidebarOpen, closeSidebar }) => {

  return (
    <div className={sidebarOpen ? "sidebar-responsive": ""} id="sidebar">
        <div className="sidebar__title">
            <div className="sidebar__img">
                <img src={condLogo} alt="logo"/>
                {/* <h1>Speranza LDA</h1> */}
            </div>
            <i onClick={()=>closeSidebar()}
            className="fa fa-times"
            id="sidebarIcon"
            aria-hidden="true"
            ></i>
        </div>
        <div className="sidebar__menu">
            <div className="sidebar__link active_menu_link">
               <i> <DashboardOutlined /></i>
                <a href="/dashboard/resident/">Dashboard</a>
            </div>
            <h2>Member area</h2>
            <div className="sidebar__link">
                <i><WalletOutlined /></i>
                <a href="/dashboard/resident/deposit-simulator/">Deposito a prazo</a>
            </div>
            <div className="sidebar__link">
                <i> <TeamOutlined /></i>
                <a href="#">Membros</a>
            </div>
            <div className="sidebar__link">
                <i><SettingOutlined /></i>
                <a href="#">Definicoes</a>
            </div>
            <div className="sidebar__link">
                <i><InfoCircleOutlined /></i>
                <a href="#">Ajuda</a>
            </div>
            <div className="sidebar__logout">
                <i><ArrowLeftOutlined /></i>
                <a href="#">Sair</a>
            </div>
        </div>
    </div>
  )
}
export default Sidebar;
