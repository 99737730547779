import { useState } from "react";
import Sidebar from "../dashboard/sidebar/sidebar";
import { Outlet } from "react-router-dom";
import "../styles/styles-pages/dashboard-layout.css";
import DashboardNavbar from "../navbar/dashboard-navbar";
import DashboardMainContent from "../dashboard/main/mainContent";
import { BrowserRouter, Route } from "react-router-dom";

const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const openSidebar = () => {
    setSidebarOpen(true);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className="contador">
      <DashboardNavbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <main>
        <Outlet />
      </main>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};
export default DashboardLayout;
