import React from "react";
import "./herosection.css";
import PeopleImage from "../assets/images/peoplebubles.svg";
import { Typography } from "antd";

// import PeopleImage from "../assets/images/people.svg";
const { Title } = Typography;
const HeroSection = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <h1>
          Torne-se um Administrador <br />e Gestor De Condomínios de{" "}
        </h1>
        <span className="bold-p-sucess-color">Sucesso</span>
        {/* <Title>Torne-se um Administrador e Gestor De Condominio de  Sucesso</Title> */}
        <p className="subtitle-hero">
          <span className="subtitle-hero-hero">
            O software desenvolvido com <br /> objectivo de optimizar e agilizar
            o trabalho de síndicos e administradores.
          </span>
        </p>
      </div>
      <div className="hero-image">
        <img src={PeopleImage} alt="People img" />
      </div>
    </div>
  );
};

export default HeroSection;
