import React from "react";
import avatar from "../assets/icons/icon-avatar-user--com.svg";
import "../styles/styles-components/dashboard-navbar.css";
import { BarsOutlined, BellOutlined, MessageOutlined } from "@ant-design/icons";

const DashboardNavbar = ({ sidebarOpen, openSidebar }) => {
  return (
    <nav className="dash-navbar">
      <div className="nav__icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars" aria-hidden="true">
          <BarsOutlined />
        </i>
      </div>
      <div className="navbar__left">
        <a href="#">Produtos</a>
        <a href="#">Usuarios</a>
        <a href="#" className="active__link">
          Admin
        </a>
      </div>
      <div className="navbar__right">
        <a href="#">
          <i className="fa fa-search">
            <MessageOutlined />
          </i>
        </a>

        <a href="#">
          <i className="fa fa-search">
            <BellOutlined />
          </i>
        </a>

        <a href="#">
          <img width="30px" src={avatar} alt="avatar" />
        </a>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
