import React from "react";
import { Layout } from "antd";
import condLogo from "../../components/assets/logo/logo-condominio.svg";

function LoginHeader() {
  return (
    <Layout.Header style={{ background: "#fff" }}>
      <div>
        <img src={condLogo} alt="condLogo" style={{ marginLeft: " 16%" }} />
      </div>
    </Layout.Header>
  );
}

export default LoginHeader;
